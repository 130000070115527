import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './App.css';
import Layout from "./components/layout/layout";
import Home  from "./pages/Home/Home";
import AboutUs from "./pages/AboutUs/AboutUs";
import ContactUs from "./pages/ContactUs/ContactUs";
import Services from "./pages/Services/Services";
import CallCenter from "./pages/Services/CallCenter/CallCenter";
import CustomerSupport from "./pages/Services/CallCenter/CustomerSupport/CustomerSupport";
import VirtualAssistant from "./pages/Services/CallCenter/VirtualAssistant/VirtualAssistant";
import Engineering from "./pages/Services/Engineering/Engineering";
import MechanicalEngineering from "./pages/Services/Engineering/MechanicalEngineering/MechanicalEngineering";
import CivilEngineering from "./pages/Services/Engineering/CivilEngineering/CivilEngineering";
import Financial from "./pages/Services/Financial/Financial";
import Bookkeeping from "./pages/Services/Financial/Bookkeeping/Bookkeeping";
import Accounting from "./pages/Services/Financial/Accounting/Accounting";
import HealthCare from "./pages/Services/HealthCare/HealthCare";
import ClaimsAdjudication from "./pages/Services/HealthCare/ClaimsAdjudication/ClaimsAdjudication";
import MedicalBillingCoding from "./pages/Services/HealthCare/MedicalBillingCoding/MedicalBillingCoding";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import ThankYou from "./pages/ThankYou/ThankYou";
import Blog from "./pages/blog/blog";
import BlogInnerPage from "./pages/blogInnerPage/blogInnerPage";
import ScrollToTop from "./components/ScrollToTop"
import { BlogReposContextProvider } from "./components/BlogContext/BlogContext";
import Helmets from "./components/Helmets/Helmets";


function App() {

  return (
    <>
    <BlogReposContextProvider>
    <BrowserRouter>
    <ScrollToTop>
      <Helmets></Helmets>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about-us/" element={<AboutUs />} />
          <Route path="/contact-us/" element={<ContactUs />} />
          <Route path="/services/" element={<Services />} />
          <Route path="/services/call-center-services/" element={<CallCenter />} />
          <Route path="/services/call-center-services/customer-support-service/" element={<CustomerSupport />} />
          <Route path="/services/call-center-services/virtual-assistant-service/" element={<VirtualAssistant />} />
          <Route path="/services/engineering-services/" element={<Engineering />} />
          <Route path="/services/engineering-services/mechanical-engineering-service/" element={<MechanicalEngineering />} />
          <Route path="/services/engineering-services/civil-engineering-service/" element={<CivilEngineering />} />
          <Route path="/services/financial-accounting-services/" element={<Financial />} />
          <Route path="/services/financial-accounting-services/bookkeeping-service/" element={<Bookkeeping />} />
          <Route path="/services/financial-accounting-services/accounting-service/" element={<Accounting />} />
          <Route path="/services/healthcare-bpo-services/" element={<HealthCare />} />
          <Route path="/services/healthcare-bpo-services/claim-adjudication-service/" element={<ClaimsAdjudication />} />
          <Route path="/services/healthcare-bpo-services/medical-billing-and-coding-service/" element={<MedicalBillingCoding />} />
          <Route path="/blog/" element={<Blog />} />
          <Route path="/blog/:blogUrl/" element={<BlogInnerPage />} />
          <Route path="/thank-you/" element={<ThankYou />} />
          <Route path="/not-found/" element={<NotFoundPage/>}/>
          <Route path="*" element={<Navigate to="/not-found/" />} />
        </Route>
      </Routes>
      </ScrollToTop>
    </BrowserRouter>
    </BlogReposContextProvider>
</>
  );
}

export default App;
