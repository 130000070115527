import React from 'react';
// import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fontsource-variable/dm-sans"; // Defaults to wght axis
import "@fontsource/syne"; // Defaults to weight 400
import "@fontsource/syne/500.css"; // Specify weight
import "@fontsource/syne/600.css"; // Specify weight
import "@fontsource/yantramanav/100.css"; // Specify weight
import "@fontsource/yantramanav/300.css"; // Specify weight
import "@fontsource/yantramanav"; // Defaults to weight 400
import "@fontsource/yantramanav/500.css"; // Specify weight
import "@fontsource/yantramanav/700.css"; // Specify weight
import "@fontsource/yantramanav/900.css"; // Specify weight

import "./assets/css/fontawesome.css";
import "./assets/css/regular.css";
import "./assets/css/solid.css";

import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';

import "./assets/js/theme-custom.js";
import { createRoot } from "react-dom";

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  createRoot(rootElement).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  createRoot(rootElement).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
}

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
