import React from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import Bgshape6 from "../../assets/images/resource/bg-shape-6.svg";
import Bgshape5 from "../../assets/images/resource/bg-shape-5.svg";
import PT from "../../assets/images/resource/about1.png";
import PT1 from "../../assets/images/resource/about2.png";
import As3 from "../../assets/images/resource/about3.png";
import Bgshape11 from "../../assets/images/resource/bg-shape-11.svg";

const AboutUs = () => {
  return (
    <>
      <section className="hero-section-wrap">
        <div className="hero-section-content-wrap">
          <Image src={Bgshape6} className="bg-shape slide-left" alt="Shape" />
          <Image src={Bgshape5} className="bg-shape2 slide-right" alt="Shape" />
          <div className="custom-container">
            <div className="hero-section-content text-center">
              <h1 className="section-subtitle">About Us</h1>
              <h2 className="section-title fade-in">Who We Are</h2>
              <p>
                At Skilloyz, we are redefining the essence of Business Process
                Outsourcing. Our innovative and client-centric strategy has made
                us an emerging leader in the BPO market, with strong presence in
                both Australia and the UK. Our team, a blend of seasoned
                professionals and dynamic young talent, is dedicated to
                delivering outsourcing solutions that are not just effective but
                transformative. We believe in the power of global insights
                combined with local expertise, offering a service that
                understands and respects the uniqueness of each market we
                operate in. 
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="about-service4-area about-service3-area pt-0">
        <div className="custom-container">
          <div className="about-service4-inner d-flex align-items-center">
            <div className="img-box">
              <Image src={PT} alt="About" />
            </div>

            <div className="content-box">
              <h1>What We Do</h1>
              <p>
                Our services at Skilloyz encompass a comprehensive range of BPO
                solutions designed to cater to the ever-evolving needs of
                businesses across various industries. From managing customer
                interactions with utmost professionalism to handling back-office
                operations with precision, we cover all aspects of outsourcing.
              </p>
              <p>
                Our expertise extends to specialized domains such as accounting,
                data management, IT support, and beyond. Our commitment lies in
                providing services that are not only of the highest quality but
                are also tailored to the specific requirements of each client,
                ensuring a perfect fit for their business needs.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="about-service4-area about-service3-area pt-0">
        <div className="custom-container">
          <div className="about-service4-inner d-flex align-items-center">
            <div className="content-box">
              <h1>Our Aim</h1>
              <p>
                The vision of Skilloyz is to set a new benchmark in the world of
                BPO services. We are more than a service provider; we are a
                partner in your growth and success. Our aim is to deliver
                solutions that not only solve immediate business challenges but
                also pave the way for future growth and efficiency.
              </p>
              <p>
                We leverage state-of-the-art technology, coupled with our team's
                expertise, to create solutions that are innovative, scalable,
                and aligned with the latest industry trends. Our focus is on
                building enduring relationships with our clients, understanding
                that our success is intrinsically linked to theirs.
              </p>
            </div>
            <div className="img-box">
              <Image src={PT1} alt="About" />
            </div>
          </div>
        </div>
      </section>

      <section className="about-service3-area">
        <div className="custom-container">
          <div className="custom-row align-items-center">
            <div className="img-box">
              <Image src={As3} alt="About" />
            </div>

            <div className="content-box">
              <h1>Our Commitment to Excellence</h1>
              <p>
                At Skilloyz, we are committed to excellence in every aspect of
                our service. We continually invest in our people and technology
                to stay ahead of the curve, ensuring that our clients always
                receive the best possible service. We understand the importance
                of adapting to changing market dynamics, and our agile approach
                allows us to respond quickly to the evolving needs of our
                clients.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="cta-area cta-style-3">
        <div className="custom-container">
          <div className="cta-body text-center">
            <Image
              src={Bgshape11}
              className="animation-slide-left bg-shape"
              alt="Shape"
            />
            <h2>Join Us on Our Journey</h2>
            <p>
              We invite you to join us on this exciting journey as we strive to
              not just meet but exceed the expectations of the businesses we
              serve. With Skilloyz, you gain more than a service provider; you
              gain a partner who is committed to your success. Let us help you
              navigate the complexities of the modern business landscape,
              turning challenges into opportunities for growth and innovation.</p>
            <Link to="/contact-us/" className="theme-btn"> Contact Us now</Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
