import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import Logo from '../../assets/images/logo1.png';

const header = () => {
  return (
   <>

    <div className="header-bar">
        <div className="custom-container">
            <div className="header-bar-body d-flex align-items-center justify-content-between">
                <div className="left">
                    {/* <select className="country-select" name="country" id="country">
                        <option value="au" className="au-flag">AU</option>
                        <option value="uk">UK</option>
                    </select> */}
                </div>
                <div className="right">
                    <p>
                        Level up your business with <b>SKILLOYZ</b>.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <header className="header-area">

        <div className="custom-container">
            <div className="custom-row align-items-center justify-content-between">
                <div className="header-left d-flex align-items-center">
                    <Link to="/" className="logo">
                        <Image src={Logo} alt="Logo" fluid />
                    </Link>

                    <div className="header-left-right">
                        <Link to="/contact-us/" className="theme-btn">Contact Us</Link>
                        <span className="menu-bar">
                            <i className="las la-bars"></i>
                        </span>
                    </div>
                    <nav className="navbar-wrapper">
                        <span className="close-menu-bar">
                            <i className="las la-times"></i>
                        </span>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li className="mega-menu-item">
                                <Link to="/services/">Services</Link>
                                <span className="dropdown-menu-item-icon">
                                    <i className="las la-angle-down"></i>
                                </span>
                                <div className="mega-menu">
                                    <div className="mega-menu-inner">
                                        <div className="custom-container d-flex">
                                            <div className="left">
                                                <div
                                                    className="mega-menu-link-wrap d-flex align-items-start justify-content-between">
                                                    <div className="mega-menu-links d-flex">
                                                        <div className="mega-menu-link">
                                                            <p><Link to="/services/call-center-services/">Call Center Services</Link></p>
                                                            <ul>
                                                                <li><Link to="/services/call-center-services/customer-support-service/">Customer Support Service</Link></li>
                                                                <li><Link to="/services/call-center-services/virtual-assistant-service/">Virtual Assistant Service</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="mega-menu-link">
                                                            <p><Link to="/services/engineering-services/">Engineering Services</Link></p>
                                                            <ul>
                                                                <li><Link to="/services/engineering-services/civil-engineering-service/">Civil Engineering Service</Link></li>
                                                                <li><Link to="/services/engineering-services/mechanical-engineering-service/">Mechanical Engineering Service</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="mega-menu-link">
                                                            <p><Link to="/services/financial-accounting-services/">Financial Accounting Services</Link></p>
                                                            <ul>
                                                                <li><Link to="/services/financial-accounting-services/bookkeeping-service/">Bookkeeping Service</Link></li>
                                                                <li><Link to="/services/financial-accounting-services/accounting-service/">Accounting Service</Link></li>
                                                            </ul>
                                                        </div>
                                                        <div className="mega-menu-link">
                                                            <p><Link to="/services/healthcare-bpo-services/">HealthCare BPO Services</Link></p>
                                                            <ul>
                                                                <li><Link to="/services/healthcare-bpo-services/medical-billing-and-coding-service/">Medical Billing & Coding Service</Link></li>
                                                                <li><Link to="/services/healthcare-bpo-services/claim-adjudication-service/">Claim Adjudication Service</Link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    className="mega-meu-footer d-flex align-items-center justify-content-between w-full">
                                                    <ul className="mega-menu-social d-flex align-items-center">
                                                        <li>
                                                            <Link to="https://www.facebook.com/profile.php?id=61550777652975" target="_blank"><i className="iconoir-facebook"></i></Link>
                                                        </li>
                                                        <li>
                                                            <Link to="https://www.linkedin.com/company/99967621/" target="_blank"><i className="iconoir-linkedin"></i></Link>
                                                        </li>
                                                        <li>
                                                            <Link to="https://www.instagram.com/skilloyz/" target="_blank"><i className="iconoir-instagram"></i></Link>
                                                        </li>
                                                        <li>
                                                            <Link to="https://www.pinterest.com.au/skilloyz/" target="_blank"><i className="iconoir-pinterest"></i></Link>
                                                        </li>
                                                    </ul>

                                                    <p>Looking for new service?</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li><Link to="/about-us/">About Us</Link></li>
                            <li><Link to="/blog/">Blog</Link></li>
                            <li><Link to="/contact-us/">Contact Us</Link></li>
                        </ul>
                    </nav>
                </div>
                <div className="header-right">
                    <div className="header-contact-info d-flex align-items-center">
                        <div className="phone-number">
                            <Link to="mailto:info@skilloyz.com">
                                Email <i className="iconoir-arrow-up-right"></i>
                            </Link>
                            info@skilloyz.com
                        </div>
                        <Link to="/contact-us/" className="theme-btn">Contact Us</Link>
                    </div>
                </div>
            </div>
        </div>

    </header>
   </>
  )
}

export default header