import { React, useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";
import Supporticon from '../../assets/images/resource/support-icon.svg';
import Mapicon from '../../assets/images/resource/map-icon.svg';
import { useForm } from 'react-hook-form';
import {ValidationRules} from "../ValidationRules";
import Bowser from "bowser";
import ReCAPTCHA from "react-google-recaptcha"

import axios from "axios";
const baseURL = process.env.REACT_APP_API_URL + "/Postcode/form_list";
const SITE_KEY = process.env.REACT_APP_SITE_KEY;

const ContactForm = () => {
    const captchaRef = useRef(null)
    const PageTitle = JSON.parse(localStorage.getItem('PageTitle'));
    const  FormType = "Contact Form";
    const [Browser, set_Browser] = useState("");
    const [OS, set_OS] = useState("");
    const getBroserOs = async () => {
        const browser = Bowser.getParser(window.navigator.userAgent);
        set_Browser(browser.getBrowserName());
        set_OS(browser.getOSName());
    };

    const [UserIP, setUserIP] = useState("");
    const getIP = async () => {
        const res = await axios.get("https://api.ipify.org?format=json");
        setUserIP(res.data.ip);
    };
    
    useEffect(() => {
            getIP();
            getBroserOs();
    },[]);

    const navigate = useNavigate();
    const { register, handleSubmit, formState, formState: { errors } } = useForm({mode: "onBlur"});

    const onSubmit = async (data) => {

        const captchaValue = captchaRef.current.getValue();
        if (!captchaValue) {
            alert("Please verify the reCAPTCHA!");
        }else{ 
            const formdata = { 
                ...data,
                FormType: FormType,
                UserIP: UserIP,
                Browser: Browser,
                OS: OS,
                Page: PageTitle,
                recaptcha_response: captchaValue
            };
            
            try {
                const response = await axios.post(baseURL, formdata);
                if (response.data.status === true) {
                    navigate('/thank-you/');
                } else {
                    navigate('/');
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };
    
    const validation = {
        name: { 
            required: "First Name is required",
            pattern: {
                value: ValidationRules.letters,
                message: "Enter only letters",
            }
        },
        email: { 
            required: "Email is required",
            pattern: {
                value: ValidationRules.email,
                message: "Enter valid Email",
            }
        },
        phone_no: {
            required: "Phone No is required",
            pattern: {
                value: ValidationRules.digits,
                message: "Enter only digits",
            },
            minLength: {
                value: 10,
                message: "Please enter minimum 10 digits"
            }
        },
        interested_in: { 
            required: "Service is required",
        },
        country: { 
            required: "Country is required",
        },
    };

    

  return (
    <>
        <section className="contact-area">
            <div className="custom-container">
                <div className="custom-row">
                    <div className="contact-form-wrap">
                        <div className="contact-form-body">
                            <h4 className="section-subtitle">Contact</h4>
                            <h5 className="section-title">Lets get in touch</h5>
                            <p>You can reach us anytime via <Link to="mailto:info@skilloyz.com">info@skilloyz.com </Link></p>
                            <form onSubmit={handleSubmit(onSubmit)} className="contact-form">
                                <input type="hidden" {...register('FormType') } value={FormType} />
                                <input type="hidden" {...register('UserIP') } value={UserIP} />
                                <input type="hidden" {...register('Browser') } value={Browser} />
                                <input type="hidden" {...register('OS') } value={OS} />
                                <input type="hidden" {...register('Page') } value={PageTitle} />
                                <div className="input-row">
                                    <div className="input-group">
                                        <label>Name</label>
                                        <input type="text" placeholder="Your Name" {...register('name', validation.name) } />
                                        <small className="text-danger custom-error">
                                            {errors?.name && errors.name.message}
                                        </small>
                                    </div>
                                    <div className="input-group">
                                        <label>Email</label>
                                        <input type="text" placeholder="Your Email" {...register('email', validation.email) } />
                                        <small className="text-danger custom-error">
                                            {errors?.email && errors.email.message}
                                        </small>
                                    </div>
                                </div>
                                <div className="input-row">
                                    <div className="input-group phone-number position-relative">
                                        <select className="number-prefix">
                                            <option value="au">AU</option>
                                            <option value="uk">UK</option>
                                        </select>
                                        <label>Phone No</label>
                                        <input type="text" maxLength="10" placeholder="Your Number" {...register('phone_no', validation.phone_no) } />
                                        <small className="text-danger custom-error">
                                            {errors?.phone_no && errors.phone_no.message}
                                        </small>
                                    </div>
                                    <div className="input-group">
                                        <label>Site URL</label>
                                        <input type="text" placeholder="Site URL" {...register('siteurl') } />
                                    </div>
                                </div>
                                <div className="input-row">
                                    <div className="input-group">
                                        <label>Interested In</label>
                                         <select className="form-control form-select w-100" {...register('interested_in', validation.interested_in) }>
                                            <option value="">Select Services</option>
                                            <option value="Call Center Services">Call Center Services</option>
                                            <option value="Customer Support Service">Customer Support Service</option>
                                            <option value="Virtual Assistant Service">Virtual Assistant Service</option>
                                            <option value="Engineering Services">Engineering Services</option>
                                            <option value="Civil Engineering Service">Civil Engineering Service</option>
                                            <option value="Mechanical Engineering Service">Mechanical Engineering Service</option>
                                            <option value="Financial Accounting Services">Financial Accounting Services</option>
                                            <option value="Bookkeeping Service">Bookkeeping Service</option>
                                            <option value="Accounting Service">Accounting Service</option>
                                            <option value="HealthCare BPO Services">HealthCare BPO Services</option>
                                            <option value="Medical Billing & Coding Service">Medical Billing & Coding Service</option>
                                            <option value="Claim Adjudication Service">Claim Adjudication Service</option>
                                        </select>
                                        <small className="text-danger custom-error">
                                            {errors?.interested_in && errors.interested_in.message}
                                        </small>
                                    </div>
                                    <div className="input-group">
                                        <label>Country</label>
                                         <select className="form-control form-select w-100" {...register('country', validation.country) }>
                                            <option value="">Select Your Country</option>
                                            <option value="uk">United Kingdom</option>
                                            <option value="us">Australia</option>
                                        </select>
                                        <small className="text-danger custom-error">
                                            {errors?.country && errors.country.message}
                                        </small>
                                    </div>
                                </div>
                                <div className="input-row">
                                    <div className="input-group">
                                        <label>Requirements</label>
                                        <textarea placeholder="Enter Your Requirement...." {...register('message') }></textarea>
                                    </div>
                                </div>
                                
                                <div className="input-row">
                                    <div className="input-group">
                                    <ReCAPTCHA sitekey={SITE_KEY} ref={captchaRef} />
                                    </div>
                                </div>

                                <div className="input-row">
                                    <div className="input-group">
                                        <button type="submit" id="submit" className="theme-btn" disabled={formState.isSubmitting}>{formState.isSubmitting ? 'Submitting...' : 'Submit'}</button>
                                    </div>
                                </div>
                                {/* <div className="input-row">
                                    <div className="input-group alert-notification">
                                        <div id="alert-message" className="alert-msg"></div>
                                    </div>
                                </div> */}
                            </form>
                        </div>
                    </div>
                    
                    <div className="contact-infos">
                        <div className="contact-infos-inner">
                            <div className="contact-info">
                                <Image src={Supporticon} alt="Support"/>
                                <h3>Contact Info</h3>
                                <p>
                                    info@skilloyz.com
                                </p>
                            </div>
                            <div className="contact-office-info contact-info">
                                <Image src={Mapicon} alt="Map"/>
                                <h3>INDIA office</h3>
                                <p>12th Floor, Fortune Business Hub, Science City Rd, near Shell Petrol Pump, Sola, Ahmedabad, Gujarat 380060</p>
                                
                            </div>

                            <ul className="contact-social-links">
                                <li>
                                    <Link to="https://www.facebook.com/profile.php?id=61550777652975" target="_blank"><i className="iconoir-facebook"></i>
                                        Facebook
                                        </Link>
                                </li>
                                <li>
                                    <Link to="https://www.linkedin.com/company/99967621/" target="_blank"><i className="iconoir-linkedin"></i>
                                        Linkedin
                                        </Link>
                                </li>
                                <li>
                                    <Link to="https://www.instagram.com/skilloyz/" target="_blank"><i className="iconoir-instagram"></i>
                                            Instagram
                                        </Link>
                                </li>
                                <li>
                                    <Link to="https://www.pinterest.com.au/skilloyz/" target="_blank"><i className="iconoir-pinterest"></i>
                                            Pinterest
                                        </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default ContactForm