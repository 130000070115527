import React from "react";
import ContactForm from "../../components/ContactForm/ContactForm";
import { Link } from 'react-router-dom';
import { Image } from "react-bootstrap";
import Serviceicon1 from '../../assets/images/icon/Call-Center-Services.png';
import Serviceicon2 from '../../assets/images/icon/Engineering-Services.png';
import Serviceicon3 from '../../assets/images/icon/Financial-Services.png';
import Serviceicon4 from '../../assets/images/icon/HealthCare-BPO-Services.png';
import Service1 from '../../assets/images/service/service1.png';
import Service2 from "../../assets/images/service/service2.png";

const services = () => {
  return (
    <>
      <section className="hero-section-wrap">
        <div className="hero-section-content-wrap">
          <div className="custom-container">
            <div className="hero-section-content text-center">
              <h1 className="section-subtitle">Services We Offer</h1>
              <h2 className="section-title fade-in">Services</h2>
              <p></p>
            </div>
          </div>
        </div>
      </section>

      <section className="company-service-area">
        <div className="custom-container">
          <div className="hero-service-about">
            <div className="hero-service-about-body">
            <div className="services-list d-flex">
                <div className="service-card simple-shadow">
                    <Image src={Serviceicon1} alt="Service Icon" className="service-icon" />
                    <h3><Link to="/services/call-center-services/">Call Center Services</Link></h3>
                    <p>In the dynamic world of customer relations, effective...</p>
                </div>
                <div className="service-card simple-shadow">
                <Image src={Serviceicon2} alt="Service Icon" className="service-icon" />
                    <h3><Link to="/services/engineering-services/">Engineering Services</Link></h3>
                    <p>In today's competitive market, engineering services are...</p>
                </div>
                <div className="service-card simple-shadow">
                <Image src={Serviceicon3} alt="Service Icon" className="service-icon" />
                    <h3><Link to="/services/financial-accounting-services/">Financial Services</Link></h3>
                    <p>In the intricate world of finance, Skilloyz stands as a beacon...</p>
                </div>
                <div className="service-card simple-shadow">
                <Image src={Serviceicon4} alt="Service Icon" className="service-icon" />
                    <h3><Link to="/services/healthcare-bpo-services/">HealthCare BPO Services</Link></h3>
                    <p>Skilloyz offers comprehensive Healthcare BPO services to...</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-service4-area about-service3-area pt-0">
            <div className="custom-container">
                <div className="about-service4-inner d-flex align-items-center">
                    <div className="img-box">
                        <Image src={Service1} alt="About" />
                    </div>
                    <div className="content-box">
                        <p>Skilloyz stands as a dynamic and versatile BPO/Outsourcing company, expertly navigating the complexities of modern business needs. This company is distinguished by its comprehensive range of services designed to streamline operations, enhance customer engagement, and boost overall efficiency for its clients. With a strong focus on customization and client-centric solutions, Skilloyz excels in adapting its services to the unique requirements of each business it partners with.</p>
                        <p>From robust customer support to specialized back-office operations, and cutting-edge data analytics to industry-specific services, Skilloyz is dedicated to empowering businesses to focus on their core competencies while it expertly handles the ancillary yet critical operational aspects.</p>
                    </div>
                </div>
            </div>
        </section>

        <section className="about-service3-area">
        <div className="custom-container">
          <div className="custom-row align-items-center">
            <div className="content-box">
              <p>
              At Skilloyz, our approach to each project is dynamic and tailored, recognizing the ever-evolving needs of our clients. From the moment a project is initiated, we embark on a journey of meticulous planning and execution. Our process starts with a comprehensive analysis of the client’s specific needs and challenges, laying the groundwork for bespoke solutions. 
              </p>
              <p>Skilled teams across various departments, from customer support to technical assistance and back-office management, work in unison to ensure seamless service delivery. In essence, Skilloyz is committed to adapting and evolving, ensuring that our solutions not only meet but exceed the expectations of our diverse clientele.</p>
            </div>
            <div className="img-box">
              <Image src={Service2} alt="About" />
            </div>
          </div>
        </div>
      </section>

      <ContactForm></ContactForm>

    </>
  );
};

export default services;
