import React from 'react';
import { Image } from 'react-bootstrap';
import Bgshape6 from "../../assets/images/resource/bg-shape-6.svg";
import Bgshape5 from "../../assets/images/resource/bg-shape-5.svg";

const ThankYou = () => {
  return (
    <>
          <section className="hero-section-wrap">
        <div className="hero-section-content-wrap">
          <Image src={Bgshape6} className="bg-shape slide-left" alt="Shape" />
          <Image src={Bgshape5} className="bg-shape2 slide-right" alt="Shape" />
          <div className="custom-container">
            <div className="hero-section-content text-center">
              <h1 className="section-subtitle">Thank You</h1>
              <h2 className="section-title fade-in">Thank You For Contacting Us</h2>
              <p>We will be in touch with you shortly.</p>
              <p>Meanwhile if you want to talk to our consultants to find out more, please email us on info@skilloyz.com.</p>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default ThankYou;