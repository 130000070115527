import { React, useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Image } from "react-bootstrap";
import { ValidationRules } from "../../../../frontend/src/components/ValidationRules.js";
import { useForm } from 'react-hook-form';
import Bowser from "bowser";
import axios from "axios";
import { useBlogRepos } from '../../components/BlogContext/BlogContext.js';
import { useParams } from "react-router-dom";
import dateFormat from "dateformat";
import parse from 'html-react-parser';
import ReCAPTCHA from "react-google-recaptcha"

const baseURL = process.env.REACT_APP_API_URL + "/Postcode/form_list";
const SITE_KEY = process.env.REACT_APP_SITE_KEY;

const backendImageUrl = process.env.REACT_APP_BLOG_IMG_URL + "/uploads/blog/";

const BlogInnerPage = () => {
    const navigate = useNavigate();
    const captchaRef = useRef(null)
    const { blogRepos } = useBlogRepos();

    const LatestReverse = blogRepos.slice(0).reverse().map(element => {
        return element;
    });
    const params = useParams();

    const [currentRepo, setCurrentRepo] = useState(null);
    const [currentRepoUrl, setCurrentRepoUrl] = useState(null);
    const [ContentVariable, setContentVariable] = useState('')
    const location = useLocation();

    const page_url = window.location.href;

    useEffect(() => {
        
        const path = location.pathname;
        const url_slug = path.split("/").slice(-2)[0];
        const findRepo = () => {
        const response = blogRepos.find((repo) => repo.Url === params.blogUrl);
        if (response) {
        setCurrentRepo(response);
        setCurrentRepoUrl(response.Url)
        setContentVariable(response?.Content?.toString() || '');
        }
        
        }
        if(!(params.blogUrl === url_slug)){
            navigate('/not-found/');
        } else {
        findRepo();
        }
        }, [blogRepos, params.blogUrl]);

    const reactContentVariable = parse(ContentVariable);

    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 7;

    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = LatestReverse.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(LatestReverse.length / itemsPerPage);

    // Invoke when the user clicks to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % LatestReverse.length;
        setItemOffset(newOffset);
    };

    const PageTitle = JSON.parse(localStorage.getItem('PageTitle'));
    const FormType = "Contact Form";
    const [Browser, set_Browser] = useState("");
    const [OS, set_OS] = useState("");
    const getBroserOs = async () => {
        const browser = Bowser.getParser(window.navigator.userAgent);
        set_Browser(browser.getBrowserName());
        set_OS(browser.getOSName());
    };

    const [UserIP, setUserIP] = useState("");
    const getIP = async () => {
        const res = await axios.get("https://api.ipify.org?format=json");
        setUserIP(res.data.ip);
    };

    useEffect(() => {
        getIP();
        getBroserOs();
    }, []);

    const { register, handleSubmit, formState, formState: { errors } } = useForm({ mode: "onBlur" });

    const onSubmit = async (data) => {
       

        const captchaValue = captchaRef.current.getValue();
        if (!captchaValue) {
            alert("Please verify the reCAPTCHA!");
        } else {
            const formdata = {
                ...data,
                FormType: FormType,
                UserIP: UserIP,
                Browser: Browser,
                OS: OS,
                Page: PageTitle,
                recaptcha_response: captchaValue
            };
          

            try {
                const response = await axios.post(baseURL, formdata);
                if (response.data.status === true) {
                    navigate('/thank-you/');
                } else {
                    navigate('#');

                }
            } catch (error) {
                console.error('Error:', error);
            }
        }

    };

    const validation = {
        name: {
            required: "First Name is required",
            pattern: {
                value: ValidationRules.letters,
                message: "Enter only letters",
            }
        },
        email: {
            required: "Email is required",
            pattern: {
                value: ValidationRules.email,
                message: "Enter valid Email",
            }
        },
        phone_no: {
            required: "Phone No is required",
            pattern: {
                value: ValidationRules.digits,
                message: "Enter only digits",
            },
            minLength: {
                value: 10,
                message: "Please enter minimum 10 digits"
            }
        },
        interested_in: {
            required: "Service is required",
        },
        country: {
            required: "Country is required",
        },
    };
    return (
        <div>
            <section className='bloginner-title-section'>
                <div className='custom-container'>
                    <div className='bloginner-title-section-inner' style={{backgroundImage: `url(${backendImageUrl}${currentRepo?.BlogBanner})`}}>
                        <div className='bloginner-title-section-bg-layer'> </div>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <h1 className='bloginner-title blog-section-title section-title fade-in'>{currentRepo?.Title}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='bloginner-content-section'>
                <div className='custom-container'>
                    <div className='row'>
                        <div className='col-lg-8'>
                            <div className="blog-details-area">
                                <div className="blog-details-body">
                                    <div className="blog-details-inner">
                                        <div className="blog-details-introduction">
                                            <h6 className='bloginner-date '>{dateFormat(currentRepo?.Date, "mmmm dS, yyyy")}</h6>
                                            {reactContentVariable}
                                            <ul className="contact-social-links-blog">
                                                <li><p className='m-0 text-blue text-bold'>Share post on</p></li>
                                                <li>
                                                    <Link to={`https://www.facebook.com/sharer/sharer.php?u=${page_url}`} target="_blank"><i className="iconoir-facebook"></i></Link>
                                                </li>
                                                <li>
                                                    <Link to={`https://twitter.com/intent/tweet?url=${page_url}`} target="_blank"><i className="iconoir-twitter"></i></Link>
                                                </li>
                                                <li>
                                                    <Link to={`https://www.linkedin.com/shareArticle?mini=true&url==${page_url}`} target="_blank"><i className="iconoir-linkedin"></i></Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 '>
                            <div className='form-sticky-top'>
                                <div className='recent-blog sticky-news '>
                                    <h4 className='section-subtitle recent-blog-maintitle'>Latest Blogs</h4>
                                    <div className='blog-outer-box'>
                                        {currentItems && currentItems.slice(0, 3).map(({ Title, Url, Id, ThumbnailImage, ThumbnailImageAlt, Date }) => (
                                            <div className='recent-blog-inner' key={Id}>
                                                <div className='row'>
                                                    <div className='col-5 my-auto'>
                                                        <Image src={`${backendImageUrl}${ThumbnailImage}`} fluid alt={ThumbnailImageAlt}></Image>
                                                    </div>
                                                    <div className='col-7 my-auto'>
                                                        <h5 className='recent-blog-title'>{Title.length <= 30 ? Title : Title.substring(0, 15)}...</h5>
                                                        <p className='section-subtitle recent-blog-date mb-2'>{dateFormat(Date, "mmmm dS, yyyy")}</p>
                                                        <Link to={`/blog/${Url}/`} className="theme-btn py-2">
                                                            <span>Read More</span> <i className="icon-right iconoir-arrow-up-right"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className='blog-sticky-form recent-blog sticky-news '>
                                    <h4 className='section-subtitle recent-blog-maintitle'>Request A Quote</h4>
                                    <form onSubmit={handleSubmit(onSubmit)} className="contact-form">
                                        <div className='input-row'>
                                            <div className="input-group">
                                                <input type="text" placeholder="Your Name" {...register('name', validation.name)} />
                                                <small className="text-danger custom-error">
                                                    {errors?.name && errors.name.message}
                                                </small>
                                            </div>
                                        </div>
                                        <div className='input-row'>
                                            <div className="input-group">
                                                <input type="text" placeholder="Your Email" {...register('email', validation.email)} />
                                                <small className="text-danger custom-error">
                                                    {errors?.email && errors.email.message}
                                                </small>
                                            </div>
                                        </div>
                                        <div className='input-row'>
                                            <div className="input-group">
                                                <input type="text" maxLength="10" placeholder="Your Number" {...register('phone_no', validation.phone_no)} />
                                                <small className="text-danger custom-error">
                                                    {errors?.phone_no && errors.phone_no.message}
                                                </small>
                                            </div>
                                        </div>
                                        <div className="input-row">
                                            <div className="input-group">
                                                <textarea placeholder="Enter Your Requirement...." {...register('message')}></textarea>
                                            </div>
                                        </div>
                                        <div className="input-row">
                                            <div className="input-group">
                                                <ReCAPTCHA sitekey={SITE_KEY} ref={captchaRef} />
                                            </div>
                                        </div>
                                        <div className="input-row">
                                            <div className="input-group">
                                                <button type="submit" id="submit" className="theme-btn" disabled={formState.isSubmitting}>{formState.isSubmitting ? 'Submitting...' : 'Submit'}</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default BlogInnerPage