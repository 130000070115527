import React from 'react';
import ContactForm from "../../../components/ContactForm/ContactForm";
import { Image } from "react-bootstrap";
import Services1 from "../../../assets/images/service/call-center-services.png";

const CallCenter = () => {
  return (
    <>
      <section className="hero-section-wrap">
        <div className="hero-section-content-wrap">
          <div className="custom-container">
            <div className="hero-section-content text-center">
              <h1 className="section-subtitle">Call Center Services</h1>
              <h2 className="section-title fade-in">
                Skilloyz - Your Gateway to Exceptional Customer Connections
              </h2>
              <p>
                In the dynamic world of customer relations, effective
                communication and efficient services are paramount. Skilloyz
                stands as a leader in delivering top-tier Business Process
                Outsourcing (call center) services, adeptly connecting
                businesses with their customers globally. Our offerings are
                tailored to meet the changing needs of companies across various
                industries, providing custom solutions that boost customer
                satisfaction and streamline operational effectiveness.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="company-service-area pb-0">
        <div className="custom-container">
          <div className="hero-service-about">
            <Image src={Services1} alt="Service About" />
            <div className="hero-service-about-body">
              <h3>Excellence in BPO Solutions</h3>
              <p>
                At Skilloyz, we understand that the heart of a good call center
                service lies in its ability to connect and resolve. Whether it's
                a simple inquiry or a complex support request, our call center
                as a service, is equipped with the knowledge and empathy
                required to handle each interaction with utmost care and
                professionalism. We operate with the belief that each call is an
                opportunity to strengthen your brand's reputation and customer
                loyalty.
              </p>
              <h3>Diverse Range of BPO Services</h3>
              <p>
                Our range of call center as a service is comprehensive, covering
                various aspects of customer service and technical support.
              </p>
              <p className="pb-0">We provide:</p>
              <ul className="icon1">
                <li>
                  Inbound call handling, ensuring
                  that your customers always have a knowledgeable and friendly
                  voice on the line.
                </li>
                <li>
                  Outbound call center service, from
                  customer surveys to follow-up calls, increase engagement and
                  feedback.
                </li>
                <li>
                  Technical support, helping users
                  navigate through their issues with expert guidance and
                  patience.
                </li>
              </ul>
              <h3>Tailored to Your Business Needs</h3>
              <p>Skilloyz's call centers services are customized to meet the unique requirements of your business. Whether you're a startup looking to establish your customer service protocols or a large corporation needing to outsource volume-heavy customer interactions, we have the capability and scalability to support your objectives.</p>
              <h3>Commitment to Quality and Efficiency</h3>
              <p>Quality assurance is ingrained in every aspect of our operations. We continually refine our processes and train our teams to deliver services that not only meet but set industry benchmarks. Our commitment to efficiency is evident in our swift response times and high-resolution rates, ensuring that your customers always depart with a positive impression.</p>
              <h3>Empowering Australian Businesses with Skilloyz's Customer Service Expertise</h3>
              <p>In the dynamic and competitive Australian market, where businesses strive to establish a distinctive voice, Skilloyz stands as a key ally in enhancing customer interactions. Our tailored BPO solutions are specifically crafted to meet the unique challenges and opportunities of the Australian landscape. Whether your business operates out of the bustling streets of Sydney, the vibrant communities of Melbourne, or the expanding markets of Brisbane, Skilloyz’s expertise in Australian customer service norms and expectations is unmatched. With our call center Australia, we bring a blend of local insight and global standards, ensuring your brand resonates with the ethos of the Australian customer, fostering loyalty and driving growth.</p>
                <h3>Navigating the UK's Customer Service Landscape with Skilloyz</h3>
                <p>In the bustling and diverse market of the United Kingdom, where customer expectations are high and brand loyalty is hard-won, Skilloyz emerges as a beacon of exceptional customer service. Our BPO services are not just about managing calls; they're about creating meaningful connections across the UK. From London's dynamic business hubs to the cultural tapestry of cities like Manchester and Edinburgh, our UK-focused solutions are designed to resonate with the unique sensibilities of British customers. By integrating our services via the call center United Kingdom, Skilloyz ensures that your business is equipped to excel in customer engagement and satisfaction.</p>
              <h3>Local Expertise with Global Standards</h3>  
              <p>With specific expertise in catering to Australian and British markets, Skilloyz combines local understanding with global service standards. Our Call Center Australia and Call Center United Kingdom teams are adept at not only meeting but exceeding the expectations of local customers, equipped with cultural insights and market knowledge. Our Call Center Australia and Call Center United Kingdom teams also ensure your customers are well managed, making your business grow exponentially.</p>
                <h3>Why Choose Skilloyz BPO Services?</h3>
                <p>Choosing Skilloyz means opting for a partner that values your customer relationships as much as you do. With a focus on delivering seamless, high-quality call centers services, we are the behind-the-scenes support that powers your business's success. By partnering with Skilloyz, you ensure that every customer interaction is an opportunity to excel and delight.</p>
                <p>Discover the difference that professional BPO services can make for your business. At Skilloyz, we're not just a service provider; we're your partner in creating lasting customer connections.</p>
            </div>
          </div>
        </div>
      </section>

      <ContactForm></ContactForm>

    </>
  );
}

export default CallCenter