import ContactForm from "../../components/ContactForm/ContactForm";
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import Homeabout from '../../assets/images/resource/home-about.png';
import Bg1 from '../../assets/images/resource/bg1.png';
import Bgshape1 from '../../assets/images/resource/bg-shape-1.svg';
import Hwdicon1 from '../../assets/images/resource/hwd-icon-1.png';
import Hwdicon2 from '../../assets/images/resource/hwd-icon-2.png';
import Hwdicon3 from '../../assets/images/resource/hwd-icon-3.png';
import Hwdicon4 from '../../assets/images/resource/hwd-icon-4.png';
import Hwdicon5 from '../../assets/images/resource/hwd-icon-5.png';
import Hwdicon6 from '../../assets/images/resource/hwd-icon-6.png';
import Hwdicon7 from '../../assets/images/resource/hwd-icon-7.png';
import Serviceicon1 from '../../assets/images/icon/Call-Center-Services.png';
import Serviceicon2 from '../../assets/images/icon/Engineering-Services.png';
import Serviceicon3 from '../../assets/images/icon/Financial-Services.png';
import Serviceicon4 from '../../assets/images/icon/HealthCare-BPO-Services.png';

const Home = () => {
 

  return (
    <>
      <section className="hero-empowerment-area">
            <div className="custom-container">
                <div className="custom-row align-items-center">
                    <div className="hero-empowerment-left-content">
                        <h5 className="section-subtitle">ABOUT SKILLOYZ</h5>
                        <h1 className="section-title fade-in">Your partner in precise outsourcing solutions</h1>
                        <p>Our innovative and client-centric strategy has made us an emerging leader in the BPO market, with strong presence in both Australia and the UK.</p>
                        <div className="btns-group d-flex">
                            <Link to="/about-us/" className="theme-btn">Learn More </Link>
                        </div>
                    </div>

                    <div className="hero-empowerment-right-content">
                        <div className="top-content">
                            <Image src={Homeabout} className="desktop fade-in" alt="Empowerment" />
                            <Image src={Bg1} className="mobile" alt="Empowerment" />

                         
                        </div>

                      

                    </div>

                </div>
            </div>
        </section>

        <section className="how-we-do-area">
            <div className="custom-container">
                <div className="custom-row">
                    <Image src={Bgshape1} alt="Shape" className="animation-slide-left how-we-do-bg" />
                    <div className="how-we-do-left-content">
                        <div className="top">
                            <h1 className="section-title">Our approach</h1>
                            <p>Save time and money with our powerful method.</p>
                        </div>
                    </div>
                    <div className="how-we-do-right-content">
                        <div className="how-we-do-items d-flex align-items-center justify-content-center">

                            <div className="how-we-do-card">
                                <div className="circle-shape"></div>
                                <div className="line-shape"></div>

                                <div className="how-we-do-icon">
                                <Image src={Hwdicon1} alt="Establishing Contact" />
                                </div>
                                <div className="how-we-do-content">
                                    <p>Establishing<br/> Contact</p>
                                </div>
                            </div>

                            <div className="how-we-do-card">
                                <div className="circle-shape"></div>
                                <div className="line-shape"></div>

                                <div className="how-we-do-icon">
                                <Image src={Hwdicon2} alt="Setting up a meeting" />
                                </div>
                                <div className="how-we-do-content">
                                    <p>Setting Up<br/> A Meeting</p>
                                </div>
                            </div>

                            <div className="how-we-do-card">
                                <div className="circle-shape"></div>
                                <div className="line-shape"></div>

                                <div className="how-we-do-icon">
                                <Image src={Hwdicon3} alt="Requirement gathering & analysis" />
                                </div>
                                <div className="how-we-do-content">
                                    <p>Requirement <br/>Gathering & Analysis</p>
                                </div>
                            </div>

                        </div>

                        <div className="how-we-do-items d-flex align-items-center justify-content-center">
                            <div className="how-we-do-card">
                                <div className="circle-shape"></div>
                                <div className="line-shape"></div>

                                <div className="how-we-do-icon">
                                <Image src={Hwdicon4} alt="Providing appropriate solution" />
                                </div>
                                <div className="how-we-do-content">
                                    <p>Providing Appropriate<br/> Solution</p>
                                </div>
                            </div>

                            <div className="how-we-do-card">
                                <div className="circle-shape"></div>
                                <div className="line-shape"></div>

                                <div className="how-we-do-icon">
                                <Image src={Hwdicon5} alt="Pricing & Contracting" />
                                </div>
                                <div className="how-we-do-content">
                                    <p>Pricing & <br/>Contracting</p>
                                </div>
                            </div>

                          
                        </div>

                        <div className="how-we-do-items d-flex align-items-center float-end">

                        <div className="how-we-do-card">
                                <div className="circle-shape"></div>
                                <div className="line-shape"></div>

                                <div className="how-we-do-icon">
                                <Image src={Hwdicon6} alt="Project Initiation & Execution" />
                                </div>
                                <div className="how-we-do-content">
                                    <p>Project Initiation &<br/> Execution</p>
                                </div>
                            </div>

                            <div className="how-we-do-card">
                                <div className="circle-shape"></div>
                                <div className="line-shape"></div>

                                <div className="how-we-do-icon">
                                <Image src={Hwdicon7} alt="Project Management & Feedback" />
                                </div>
                                <div className="how-we-do-content">
                                    <p>Project Management &<br/> Feedback</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

        <section className="service-area">
            <div className="custom-container">
                <div className="service-section-header section-header d-flex align-items-end justify-content-between">
                    <div className="left">
                        <h5 className="section-subtitle">WHAT WE’RE OFFERING</h5>
                        <h1 className="section-title">Dealing with professional Outsourcing services.</h1>
                    </div>
                    <p>Skilloyz offers tailored call center, financial, engineering, and healthcare BPO services for businesses in Australia and the United Kingdom, providing comprehensive solutions to elevate your operations.</p>
                </div>

                <div className="services-list d-flex">
                    <div className="service-card simple-shadow">
                        <Image src={Serviceicon1} alt="Service Icon" className="service-icon" />
                        <h3><Link to="/services/call-center-services/">Call Center Services</Link></h3>
                        <p>In the dynamic world of customer relations, effective...</p>
                    </div>
                    <div className="service-card simple-shadow">
                    <Image src={Serviceicon2} alt="Service Icon" className="service-icon" />
                        <h3><Link to="/services/engineering-services/">Engineering Services</Link></h3>
                        <p>In today's competitive market, engineering services are...</p>
                    </div>
                    <div className="service-card simple-shadow">
                    <Image src={Serviceicon3} alt="Service Icon" className="service-icon" />
                        <h3><Link to="/services/financial-accounting-services/">Financial Accounting Services</Link></h3>
                        <p>In the intricate world of finance, Skilloyz stands as a beacon...</p>
                    </div>
                    <div className="service-card simple-shadow">
                    <Image src={Serviceicon4} alt="Service Icon" className="service-icon" />
                        <h3><Link to="/services/healthcare-bpo-services/">HealthCare BPO Services</Link></h3>
                        <p>Skilloyz offers comprehensive Healthcare BPO services to...</p>
                    </div>
                </div>
            </div>
        </section>

        <ContactForm></ContactForm>

    </>
  )
}

export default Home