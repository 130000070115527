import React from 'react';
import ContactForm from "../../components/ContactForm/ContactForm";
import { Image } from "react-bootstrap";
import Bgshape6 from "../../assets/images/resource/bg-shape-6.svg";
import Bgshape5 from "../../assets/images/resource/bg-shape-5.svg";
import UKflag from "../../assets/images/icon/uk-flag.png";
import AUflag from "../../assets/images/icon/au-flag.png";
import Map from "../../assets/images/resource/map.svg";


const ContactUs = () => {
  return (
    <>
    <section className="hero-section-wrap">
        <div className="hero-section-content-wrap">
          <Image src={Bgshape6} className="bg-shape slide-left" alt="Shape" />
          <Image src={Bgshape5} className="bg-shape2 slide-right" alt="Shape" />
          <div className="custom-container">
            <div className="hero-section-content text-center">
              <h1 className="section-subtitle">Contact Us</h1>
              <h2 className="section-title fade-in">We are always open to talk</h2>
            </div>
          </div>
        </div>
    </section>

    <section className="contact-area2 pb-0 d-none d-lg-block">
            <div className="custom-container">
                <div className="contact-inner">
                    <div className="contact-map-wrap">

                        
                        <div id="map">
                            <div className="map-location-item">
                                <div className="map-location-item-inner">
                                    <Image src={AUflag} alt="Flag" />
                                    <div className="content">
                                        <h3>Australia, AU</h3>
                                        {/* <p>510/2, Starc Street,
                                        North View, Australia</p> */}
                                    </div>
                                </div>
                                <span className="circle"></span>
                            </div>
                            <div className="map-location-item">
                                <div className="map-location-item-inner">
                                <Image src={UKflag} alt="Flag" />
                                    <div className="content">
                                        <h3>United Kingdom, UK</h3>
                                        {/* <p>720/5, Starc Street,
                                        North View, United Kingdom</p> */}
                                    </div>
                                </div>
                                <span className="circle"></span>
                            </div>
                            <Image src={Map} alt="map" />
                        </div>
                    </div>
                    {/* <div className="contact-inner-info-box d-flex align-items-center">
                        <div className="contact-info-box simple-shadow">
                            <div className="icon"><i className="las la-headset"></i></div>
                            <h4>Support</h4>
                            <p>Contact our fast support team</p>
                            <h6><a href="mailto:info@skilloyz.com">info@skilloyz.com</a></h6>
                        </div>
                        <div className="contact-info-box simple-shadow">
                            <div className="icon"><i className="las la-phone"></i></div>
                            <h4>Phone</h4>
                            <p>Mon-Fri from 9am to 6pm.</p>
                            <h6><a href="tel:+1-938-740-7555">+1-938-740-7555</a></h6>
                        </div>
                    </div> */}
                </div>
            </div>
    </section>

    <ContactForm></ContactForm>
    

    </>
  )
}

export default ContactUs