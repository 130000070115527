import React, { useState, useEffect } from "react";
import { useBlogRepos } from '../../components/BlogContext/BlogContext.js';
import dateFormat from "dateformat";
import { Nav, Tab, Image } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import axios from "axios";
import { Link } from "react-router-dom";
const baseURL1 = process.env.REACT_APP_API_URL + "/Blogs/GetBlogCategoriesData";

const backendImageUrl = process.env.REACT_APP_BLOG_IMG_URL + "/uploads/blog/";

const Blog = () => {
    const [categoriesRepos, setCategoriesRepos] = useState([]);

    useEffect(() => {
        axios.get(baseURL1).then((res) => {
            const myRepos = res.data;
            if (myRepos != null) {
                const filteredRepos = myRepos?.filter((repo) => repo);
                setCategoriesRepos(filteredRepos);
            } else {
                setCategoriesRepos("");
            }
        }).catch((error) => {
            console.log("Error:", error);
        });
    }, []);
    const { blogRepos } = useBlogRepos();
    const LatestReverse = blogRepos.slice(0).reverse().map(element => {
        return element;
    });
    const latestBlog = LatestReverse[0];
    // console.log(latestBlog);

    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 7;

    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = LatestReverse.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(LatestReverse.length / itemsPerPage);

    // Invoke when the user clicks to request another page.
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % LatestReverse.length;
        setItemOffset(newOffset);
    };

    return (

        <div>
            <section className="news-area">
                <div className="custom-container">
                    <div className="text-center">
                        <h1 className="section-subtitle blog-subtitle-h1 ">Blog</h1>
                    <h5 className="section-subtitle ">Browse By Categories</h5>
                   </div>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first" className='news-area-navtabs'>
                        <Nav variant="pills" className="news-area-nav">
                            <Nav.Item>
                                <Nav.Link eventKey="first">All</Nav.Link>
                            </Nav.Item>
                            {categoriesRepos && categoriesRepos.map(({ Id, Title }) => (
                                <Nav.Item>
                                    <Nav.Link eventKey={Id}>{Title}</Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <Tab.Content >
                            <Tab.Pane eventKey="first">
                                <div className="news-items align-items-start">
                                    {currentItems && currentItems.map(({ Title, Url, Id, ThumbnailImage, Description, Date, ThumbnailImageAlt }) => (
                                        <div className={`news-item card-h`} key={Id}>
                                            <div className="news-img-box">
                                                <Image src={`${backendImageUrl}${ThumbnailImage}`} alt={ThumbnailImageAlt}></Image>
                                            </div>
                                            <div className="news-item-body my-auto">
                                                <h2 className="section-title">
                                                    <Link to={`/blog/${Url}/`}>{Title.length <= 30 ? Title : Title.substring(0, 30)}...</Link>
                                                </h2>
                                                <h5 className="section-subtitle">{dateFormat(Date, "mmmm dS, yyyy")}</h5>
                                                <p>{Description.length <= 95 ? Description : Description.substring(0, 95)}...</p>
                                                <Link to={`/blog/${Url}/`} className="theme-btn">
                                                    <i className="iconoir-arrow-up-right"></i>
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {/* <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="Next >"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={pageCount}
                                    previousLabel="< Previous"
                                    renderOnZeroPageCount={null}
                                    containerClassName={'pagination'}
                                    pageLinkClassName={'pageNum'}
                                    previousLinkClassName={'pageNum'}
                                    nextLinkClassName={'pageNum'}
                                    activeLinkClassName={'pgactive'}
                                    pageCountBind={pageCount} 
                                    itemOffsetBind={itemOffset}
                                /> */}
                            </Tab.Pane>
                            {categoriesRepos && categoriesRepos.map((category) => (
                                <Tab.Pane eventKey={category.Id} key={category.Id}>
                                    <div className="news-items align-items-start">
                                        {currentItems && currentItems.map((item) =>
                                            item.CategoryId === category.Id ? (
                                                <div className={`news-item card-h`} key={item.Id}>
                                                    <div className="news-img-box">
                                                <Image src={`${backendImageUrl}${item.ThumbnailImage}`} alt="News"></Image>
                                                    </div>
                                                    <div className="news-item-body my-auto">
                                                        <h2 className="section-title">
                                                            <Link to={`/blog/${item.Url}/`}>{item.Title.length <= 30? item.Title: item.Title.substring(0, 30)}...</Link>
                                                        </h2>
                                                        <h5 className="section-subtitle">
                                                            {dateFormat(item.Date, "mmmm dS, yyyy")}
                                                        </h5>
                                                        <p>{item.Description.length <= 95 ? item.Description : item.Description.substring(0, 95)}...</p>
                                                        <Link to={`/blog/${item.Url}/`} className="theme-btn">
                                                            <i className="iconoir-arrow-up-right"></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            ) : ('')
                                        )}
                                    </div>
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </section>
        </div>
    )
}

export default Blog