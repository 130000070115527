import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import Bgshape from '../../assets/images/resource/bg-shape-4.svg';
import Logo from '../../assets/images/logo1.png';

const footer = () => {
  return (
   <>
        <footer className="footer-area">
            <Image src={Bgshape} alt="Shape" className={`animation-slide-right bg-shape`} />
  
            <div className="footer-bottom">
                <div className="custom-container">
                    <div className="custom-row row">
                        <div className="footer-links col-12 col-xl-4 col-lg-12 col-md-12 col-sm-12">
                          <Link to="/" className="logo">
                            <Image src={Logo} alt="Logo" fluid />
                          </Link>
                            <p>At Skilloyz, we are redefining the essence of Business Process Outsourcing. Our innovative and client-centric strategy has made us an emerging leader in the BPO market, with strong presence in both Australia and the UK.</p>
                        </div>
                        <div className="footer-links col-6 col-xl-2 col-lg-4 col-md-3 col-sm-3">
                            <h3>Services</h3>
                            <ul>
                                <li><Link to="/services/call-center-services/">Call Center Services</Link></li>
                                <li><Link to="/services/engineering-services/">Engineering Services</Link></li>
                                <li><Link to="/services/financial-accounting-services/">Financial Services</Link></li>
                                <li><Link to="/services/healthcare-bpo-services/">HealthCare BPO Services</Link></li>
                            </ul>
                        </div>
                        <div className="footer-links col-6 col-xl-2 col-lg-4 col-md-3 col-sm-3">
                            <h3>Company</h3>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/services/">Services</Link></li>
                                <li><Link to="/about-us/">About Us</Link></li>
                                <li><Link to="/contact-us/">Contact Us</Link></li>
                            </ul>
                        </div>
                        <div className="footer-contact-info col-12 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <div className="footer-contact-info-item">
                                <h4>Contact US</h4>
                                <p><i className="iconoir-mail"></i><Link to="mailto:info@skilloyz.com">info@skilloyz.com</Link></p>
                                <p><i className="iconoir-map-pin"></i><Link to="https://maps.app.goo.gl/yCwabLyXQyMQaLog9" target="_blank">12th Floor, Fortune Business Hub, Science City Rd, near Shell Petrol Pump, Sola, Ahmedabad, Gujarat 380060</Link></p>
                            </div>
                        </div>  
                    </div>
                </div>
            </div>

            <div className="copyright-area">
                <div className="custom-container">
                    <div className="custom-row d-flex align-items-center justify-content-between">
                        <ul className="social-links d-flex align-items-center">
                            <li>
                                <Link to="https://www.facebook.com/profile.php?id=61550777652975" target="_blank"><i className="iconoir-facebook"></i></Link>
                            </li>
                            <li>
                                <Link to="https://www.linkedin.com/company/99967621/" target="_blank"><i className="iconoir-linkedin"></i></Link>
                            </li>
                            <li>
                                <Link to="https://www.instagram.com/skilloyz/" target="_blank"><i className="iconoir-instagram"></i></Link>
                            </li>
                            <li>
                                <Link to="https://www.pinterest.com.au/skilloyz/" target="_blank"><i className="iconoir-pinterest"></i></Link>
                            </li>
                        </ul>

                        <p>Copyright © 2024 SKILLOYZ</p>
                    </div>
                </div>
            </div>
        </footer>
        
   </>
   )
}

export default footer