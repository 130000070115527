import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Bgshape6 from "../../assets/images/resource/bg-shape-6.svg";
import Bgshape5 from "../../assets/images/resource/bg-shape-5.svg";
import NotFoundImg from "../../assets/images/resource/404.png";

const NotFoundPage = () => {
  return (
    <>
      <section className="hero-section-wrap">
        <div className="hero-section-content-wrap">
          <Image src={Bgshape6} className="bg-shape slide-left" alt="Shape" />
          <Image src={Bgshape5} className="bg-shape2 slide-right" alt="Shape" />
          <div className="custom-container">
            <div className="hero-section-content text-center">
                <Image src={NotFoundImg} alt="404" />
                <h2 className="section-title mt-5 fade-in">Oops! Page Not Found</h2>
                <h3>The page you were looking for could not be found.</h3>
                <Link className="theme-btn mt-5" to="/">Return Home Page</Link>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

NotFoundPage.getInitialProps = async () => {
  document.title = "404 - Page Not Found";
  return {};
};

export default NotFoundPage;