import React, { useContext, useState, useEffect, createContext } from "react";
import axios from "axios";

export const ReposContext = createContext();
export function BlogReposContextProvider({ children }) {
  const [blogRepos, setBlogRepos] = useState([]);
  let baseUrl = process.env.REACT_APP_API_URL +"/Blogs/GetBlogData";
  useEffect(() => {
    axios.get(baseUrl).then((res) => {
      const myRepos = res.data;
      if (myRepos != null) {
      const filteredRepos = myRepos?.filter((repo) => repo);
      setBlogRepos(filteredRepos);
      }else {
        setBlogRepos("");
      }
    }).catch((error) => {
      console.log("Error:", error);
    });
  }, []);
  return (
    <ReposContext.Provider value={{ blogRepos }}>
      {children}
    </ReposContext.Provider>
  );
}

export function useBlogRepos() {
  const context = useContext(ReposContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
