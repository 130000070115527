import React from 'react'
import { Outlet } from 'react-router-dom';
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Helmets from '../Helmets/Helmets';
const Layout = () => {

  return (
    <>
    <main className="main-page homepage">
      <Helmets></Helmets>
      <Header></Header>
      <Outlet></Outlet>
      <Footer></Footer>
    </main>
    </>
  )
}

export default Layout